import cx from 'classnames'
import { useContext } from 'react'

import { type SanityExternalLinkModule } from '@data/sanity/queries/types/modules'
import { SiteContext } from '@lib/site-context'
import { getIsDarkTheme, getThemeBackgroundColor } from '@lib/theme'

import Photo from '@components/photo'

type ExternalLinkModuleProps = Pick<
  SanityExternalLinkModule,
  'url' | 'title' | 'description' | 'image'
> & {
  className?: string
}

const imageSize = 128

const ExternalLinkModule = ({
  url,
  title,
  description,
  image,
  className,
}: ExternalLinkModuleProps) => {
  const { theme } = useContext(SiteContext)

  const themeBackgroundColor = getThemeBackgroundColor(theme)
  const isDarkTheme = getIsDarkTheme(themeBackgroundColor)

  return (
    <a
      href={url}
      target="_blank"
      className={cx(
        'flex items-center p-1 gap-5 rounded-md bg-white',
        {
          'bg-opacity-10': isDarkTheme,
        },
        className,
      )}
    >
      {!!image && (
        <Photo
          image={image}
          width={imageSize}
          height={imageSize}
          imageOptions={{
            width: imageSize * 4,
            height: imageSize * 4,
          }}
          className="flex-none"
          imageClassName="rounded-md"
        />
      )}
      <div className="flex-auto">
        <h3>{title}</h3>
        {!!description && <p className="mt-1 opacity-60">{description}</p>}
      </div>
    </a>
  )
}

export default ExternalLinkModule
